import {
  DeclicConfig,
  EnvStage,
  endpoints,
  version,
} from './environment-commons';

export const environment: DeclicConfig = {
  name: EnvStage.QA,
  appVersion: require('../../package.json').version,
  production: false,
  oktaAuth: {
    clientId: '0oa9s7yubrl5qpVU20x7',
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  api: {
    baseUrl: 'https://qa.declic.te-ded.com/api/',
    baseUrlUser: 'https://api.qa.declic.te-ded.com/',
    endpoints,
  },
  version,
  websocket: {
    url: 'wss://wss.qa.declic.te-ded.com',
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000,
  },
  sentryDsn:
    'https://38e4dd1fa3ea4b288bc9b718bd497841@o258615.ingest.sentry.io/5528178',
  // sentryRelease: `declic-${version.version}`,
  homeUrl: 'None',
  faqUrl: null,
  trainingUrl: null,
  amplitudeAPIKey: '1aceb4a0b27670b5c227c2e3fe0cd0c7',
};
