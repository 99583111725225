/* eslint-disable */

import {
  ALERTING_STAGES,
  EnvStage,
  IS_ENV_PRODUCTION,
  WEB_SOCKET_CONFIG,
  WEB_SOCKET_URL,
  environment,
} from '@declic/env';
import { Observable, Subject, interval, throwError, timer } from 'rxjs';
import { finalize, mergeMap, retryWhen, takeUntil, tap } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { v4 as generateUuid } from 'uuid';

import { Inject, Injectable, Optional, inject } from '@angular/core';

import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { Coerce } from 'declic-app/common';
import { DeclicSentry } from 'src/sentry';
import { OktaAuthService } from '../okta-auth';
import { WebSocketConfig } from './websocket-config.model';

const KEEP_ALIVE_INTERVAL_MS = 480000;
const MAX_RETRY_ATTEMPTS = 2;
const SCALING_DURATION_MS = 2000;

@Injectable({
  providedIn: 'root',
})
export class NotificationService<T = any> {
  private oktaAuth = inject(OktaAuthService);
  protected webSocketSubject$: WebSocketSubject<T> | undefined;
  protected webSocketClosed$ = new Subject<boolean>();

  messages$ = new Subject<T>();

  constructor(
    @Optional()
    @Inject(WEB_SOCKET_CONFIG)
    private webSocketConfig: WebSocketConfig,
    @Inject(WEB_SOCKET_URL) private webSocketUrl: string,
    @Inject(IS_ENV_PRODUCTION) private production: boolean,
  ) {
    this.initWebSocketConnection();
    this.keepWebSocketConnectionAlive();
  }

  sendMessage(message: T): void {
    this.webSocketSubject$.next(message);
  }

  protected initWebSocketConnection(): void {
    let lastTokenUsed: AccessToken | IDToken | undefined;
    this.logDebug('initWebSocketConnection');
    this.oktaAuth
      .selectOrRenewToken('idToken')
      .pipe(
        tap((token) => (lastTokenUsed = token)),
        mergeMap((idToken) => {
          this.webSocketSubject$ = webSocket<T>({
            url: this.generateAuthUrl(
              this.webSocketUrl,
              this.buildTokenQuery(
                Coerce.toObj(idToken),
              ) /* this must be the access token */,
              this.buildTokenQuery(Coerce.toObj(idToken)),
            ),
          });
          return this.webSocketSubject$;
        }),
        retryWhen(this.genericRetryStrategy()),
      )
      .subscribe({
        next: (message) => {
          this.logDebug(message);
          this.messages$.next(message as T);
        },
        error: () => {
          this.logConnectionErr(
            'idToken' in lastTokenUsed
              ? lastTokenUsed.idToken
              : lastTokenUsed.accessToken,
          );
        },
        complete: () => {
          this.webSocketClosed$.complete();
          this.webSocketClosed$.next(true);
        },
      });
  }

  private buildTokenQuery(token: AccessToken | IDToken): string {
    if ('idToken' in token) {
      return this.filterAndJoinTokenNonce(token.idToken, token.claims.nonce);
    }
    return this.filterAndJoinTokenNonce(token.accessToken, token.claims.nonce);
  }

  private filterAndJoinTokenNonce(token: string, nonce: string): string {
    return [token, nonce || ''].join(',');
  }

  protected keepWebSocketConnectionAlive(
    message: unknown = { action: 'keep' },
  ): void {
    this.logDebug('keepWebSocketConnectionAlive');
    interval(KEEP_ALIVE_INTERVAL_MS)
      .pipe(takeUntil(this.webSocketClosed$))
      .subscribe(() => this.sendMessage(message as T));
  }

  /* [dec-5419] the best practice here is to use access tokens. id tokens are used for backwards compatibility  */
  protected generateAuthUrl(
    webSocketUrl: string,
    accessToken: string,
    idToken: string,
  ): string {
    return (
      `${webSocketUrl}?` +
      `accessToken=${accessToken}` +
      `&idToken=${idToken}` +
      `&x-correlation-id=${generateUuid()}`
    );
  }

  protected genericRetryStrategy =
    ({
      maxRetryAttempts = this.webSocketConfig?.maxRetryAttempts ??
        MAX_RETRY_ATTEMPTS,
      scalingDuration = this.webSocketConfig?.retryScalingDuration ??
        SCALING_DURATION_MS,
    }: {
      maxRetryAttempts?: number;
      scalingDuration?: number;
    } = {}) =>
    (attempts: Observable<any>) =>
      attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = ++i;
          if (retryAttempt > maxRetryAttempts) {
            return throwError(error);
          }

          this.logDebug(
            `Attempt ${retryAttempt}: retrying to connect with Web Socket ` +
              `in ${retryAttempt * scalingDuration}ms`,
          );

          return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => this.giveUpRetrying()),
      );

  private giveUpRetrying = (): void => {
    this.logDebug('Done retrying connection');
    const err = new Error('Websocket connection failed');
    err.name = 'WebsocketConnectionError';
    DeclicSentry.captureException(err);
  };

  private logConnectionErr(lastTokenUsed: string): void {
    if (ALERTING_STAGES.includes(environment.name as EnvStage)) {
      console.error({
        lastTokenUsed,
        secondsSinceEpoch: this.secondsSinceEpoch,
      });
      alert(this.buildErrMsg(lastTokenUsed));
    }
  }

  private buildErrMsg(lastTokenUsed: string): string {
    return [
      `⚠️ WS connection failed ⚠️`,
      `Access token:\n${lastTokenUsed}`,
      `Seconds since epoch: ${this.secondsSinceEpoch}`,
    ].join('\n\n');
  }

  private get secondsSinceEpoch(): string {
    return String(Math.floor(new Date().getTime() / 1000));
  }

  protected logDebug(message: any) {
    if (!this.production) {
      console.log('Notification:', message);
    }
  }
}
