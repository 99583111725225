import {
  HasActivityId,
  HasId,
  HasPayload,
  HasProjectId,
} from './generic.types';

export enum ESActivity {
  DISTRICT_HEATING_COOLING = 'dhc',
  DISTRIBUTED_SOLAR_AND_STORAGE = 'dss',
  ON_SITE_UTILS = 'osu',
  LOW_CARBON_MOBILITY = 'lcm',
  ENERGY_PERF_SERVICES = 'eps',
  LOCAL_ENERGY_GRID = 'leg',
  PUBLIC_LIGHTING = 'pl',
}

export enum RActivity {
  GREEN_POWER_GENERATION = 'gpg',
}

export enum NActivity {
  GAS_TRANSPORTATION_AND_DISTRIBUTION = 'gtd',
  LNG_TERMINALS = 'lngt',
  GAS_STORAGE = 'gs',
  ELECTRICAL_TRANSPORTATION_AND_DISTRIBUTION = 'et',
  BIOMETHANE_PRODUCTION = 'bp',
}

export type Activity = ESActivity | RActivity | NActivity;

export type BasicActivity = Readonly<{
  type: Activity;
}> &
  HasId;

export type ActivityInfo = Readonly<{
  id: string;
  type: ESActivity;
  historicalYear?: number;
  projectId: string;
}>;

export type HasActivities = Readonly<{
  activities?: BasicActivity[];
}>;

export type EditHistoricalYearParams = HasProjectId &
  HasActivityId & { historicalYear: number };

export type ActivitySummary = {
  totalProduction: number;
  heatLosses: number;
  coldLosses: number;
};
export type ActivitySummaryKeys = keyof ActivitySummary;

export enum ActivityEntities {
  HISTORILCAL_EQUIP = 'historicalEquipment',
  ENERGY_SOLD = 'energySold',
}

export type SingleActivityEntityParams = HasId & HasProjectId & HasActivityId;
export type CreateActivityEntityParams = Omit<
  SingleActivityEntityParams,
  'id'
> &
  HasPayload;
export type EditActivityEntityParams = SingleActivityEntityParams & HasPayload;
